window.Nextgenmath = window.Nextgenmath || {};

Nextgenmath.routes = {
    api_url: "https://PLACEHOLDER.api.nextgenmath.com",
    dashboard_url: "https://PLACEHOLDER.dashboard.nextgenmath.com",
    ngm_url: "https://PLACEHOLDER.nextgenmath.com",
    standards_url: "https://PLACEHOLDER.standards.nextgenmath.com",
    cdn_url: "https://cdn.nextgenmath.com",
    support_url: "https://support.nextgenmath.com/hc/en-us",
    videos_url: "https://PLACEHOLDER.videos.nextgenmath.com",
};    

let subdomain = window.location.host.split('.')[0];

// replace route placeholders
Object.keys(Nextgenmath.routes).forEach(route => {
    if (['nextgenmath', 'dashboard', 'api'].includes(subdomain)) {
        Nextgenmath.routes[route] = Nextgenmath.routes[route].replace('PLACEHOLDER.', '');
    } else {
        Nextgenmath.routes[route] = Nextgenmath.routes[route].replace('PLACEHOLDER', subdomain);
    }
});