import Vue from 'vue';
import Online from 'online-js';
import { router } from '@inertiajs/vue2';

Vue.prototype.isLocal = (process.env.NODE_ENV !== 'production');
Vue.prototype.routes = Nextgenmath.routes;
// Vue.prototype.user = Nextgenmath.user;
// Vue.prototype.viewAsUser = Nextgenmath.viewAsUser;

Vue.prototype.$inertia = router;
    
Vue.prototype.$online = Online({
    url: `${Nextgenmath.routes.api_url}/online-status/`,
    delay: 15,
    startOnload: false
});