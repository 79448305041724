const state = {
    multipliers: [
        0,
        0
    ],
    banks: {
        numbers: [
            {
                id: 1,
                value: 0,
                used: false
            },
            {
                id: 2,
                value: 0,
                used: false
            },
            {
                id: 3,
                value: 0,
                used: false
            },
            {
                id: 4,
                value: 0,
                used: false
            },
            {
                id: 5,
                value: 0,
                used: false
            },
        ],
        symbols: [
            {
                name: 'Add',
                value: "+",
                icon: "plus"
            },
            {
                name: 'Subtract',
                value: "-",
                icon: "minus"
            },
            {
                name: 'Multiply',
                value: "*",
                icon: "times"
            },
            {
                name: 'Divide',
                value: "/",
                icon: "divide"
            },
            {
                name: 'Raise to the power of',
                value: "**",
                icon: "angle-up"
            },
            {
                name: 'Open parenthesis',
                value: "(",
                icon: "bracket-round"
            },
            {
                name: 'Close parenthesis',
                value: ")",
                icon: "bracket-round-right"
            }
        ]
    },
    equation: [],
    answer: null,
}

const getters = {
    multipliers: (state) => state.multipliers,
    product: (state) => state.multipliers[0] * state.multipliers[1],
    numberBank: (state) => state.banks.numbers,
    symbolBank: (state) => state.banks.symbols,
    equation: (state) => state.equation,
    answer: (state) => state.answer,
    points: (state) => (state.banks.numbers.filter(num => num.used)).length,
    usedNumbers: (state) => state.banks.numbers.filter(num => num.used),
}

const actions = {
    updateNumbers: ({ commit, dispatch }) => {
        let multipliers = [
            Math.floor(Math.random() * 6 + 1),
            Math.floor(Math.random() * 6 + 1)
        ];
        let numberBank = [
            {
                id: 1,
                value: Math.floor(Math.random() * 6 + 1),
                used: false
            },
            {
                id: 2,
                value: Math.floor(Math.random() * 6 + 1),
                used: false
            },
            {
                id: 3,
                value: Math.floor(Math.random() * 6 + 1),
                used: false
            },
            {
                id: 4,
                value: Math.floor(Math.random() * 6 + 1),
                used: false
            },
            {
                id: 5,
                value: Math.floor(Math.random() * 6 + 1),
                used: false
            },
        ];
        commit('setMultipliers', multipliers);
        commit('setNumberBank', numberBank);
    },
    updateUsed: ({ state, commit, dispatch }, num) => {
        commit('updateUsed', {index: num.id - 1, used: !num.used});
    },
    updateEquationNumbers: ({ state, commit, dispatch }, num) => {
        let equation = JSON.parse(JSON.stringify(state.equation));
        if (num.used)
            equation.push(num.value);
        commit('updateEquationNumbers', equation);
    },
    updateEquationSymbols: ({ state, commit, dispatch }, sym) => {
        let equation = JSON.parse(JSON.stringify(state.equation));
        equation.push(sym.value);
        commit('updateEquationSymbols', equation);
    },
    evaluateEquation: ({ state, commit, dispatch }) => {
        let equation = JSON.parse(JSON.stringify(state.equation));
        equation = JSON.stringify(equation)
            .replace(/[\[\]'",]+/g, '')
            .replace('^', '**')
            .replace('&#215;', '*')
            .replace('&#247;', '/')
            .replace('--', '-')
            .replace('++', '+');
        let answer = math.evaluate(equation);

        commit('setAnswer', answer);
    },
    setEquation: ({ state, commit, dispatch }) => {
        commit('setEquation', []);
    },
    setAnswer: ({ state, commit, dispatch }, answer = null) => {
        commit('setAnswer', answer);
    },
    setUsed: ({ state, commit, dispatch }, num) => {
        let numberBank = JSON.parse(JSON.stringify(state.banks.numbers));
        numberBank.forEach(num => {
            num.used = false;
        });
        commit('setUsed', numberBank);
    },
    deleteCharacter: ({ state, commit, dispatch }) => {
        const equation = JSON.parse(JSON.stringify(state.equation));
        const index = equation.length - 1;
        const value = equation[index];

        if (index > 0) {
            equation.pop(index);
            // update used to false
            commit('deleteCharacter', equation);
            commit('setEquation', equation);

            if (typeof value == 'number') {
                const numberBank = JSON.parse(JSON.stringify(state.banks.numbers));
                const match = numberBank.find(item => item.value == value && item.used);
                dispatch('updateUsed', match);
            }
        }
        else if (index === 0) {
            dispatch('setUsed');
            commit('setEquation', []);
            commit('setAnswer', null);
        }
    },
}

const mutations = {
    setMultipliers: (state, multipliers) => state.multipliers = multipliers,
    setNumberBank: (state, numberBank) => state.banks.numbers = numberBank,
    updateUsed: (state, number) => state.banks.numbers[number.index].used = number.used,
    updateEquationNumbers: (state, equation) => state.equation = equation,
    updateEquationSymbols: (state, equation) => state.equation = equation,
    evaluateEquation: (state, equation) => equation,
    setUsed: (state, numberBank) => state.banks.numbers = numberBank,
    setEquation: (state, equation) => state.equation = equation,
    setAnswer: (state, answer) => state.answer = answer,
    deleteCharacter: (state, equation) => state.equation = equation,
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
