import { library } from '@fortawesome/fontawesome-svg-core';
import { 
    faAddressBook,
    faArchive,
    faArrowRight,
    faArrowsRotate,
    faAward,
    faBanBug,
    faBars,
    faBarsProgress,
    faBell,
    faBellOn,
    faBinoculars,
    faBookReader,
    faBooks,
    faBrain,
    faBug,
    faBuildingFlag,
    faBullseyeArrow,
    faCalculatorSimple,
    faCalendarAlt,
    faChalkboard,
    faChalkboardTeacher,
    faChalkboardUser,
    faChartBar,
    faChartSimple,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faChevronDoubleDown,
    faChevronDoubleUp,
    faCircleUser,
    faClock,
    faClipboardList,
    faCommentAltSmile,
    faCopy,
    faCubes,
    faDiagramProject,
    faDice,
    faEarthAmericas,
    faEnvelope,
    faEraser,
    faExternalLink,
    faEye,
    faFileContract,
    faFileDownload,
    faFileCsv,
    faFilePdf,
    faFileExport,
    faFilm,
    faFilters,
    faFolder,
    faFolders,
    faGear,
    faGrid2,
    faHandHoldingSeedling,
    faHeadSideBrain,
    faImage,
    faInfoCircle,
    faKeyboard,
    faLanguage,
    faLaptopHouse,
    faLink,
    faList,
    faListUl,
    faMagnifyingGlassChart,
    faNewspaper,
    faPencilAlt,
    faPoll,
    faPrint,
    faQrcode,
    faQuestionCircle,
    faRainbow,
    faRedo,
    faRightFromBracket,
    faRotateLeft,
    faRotateRight,
    faSave,
    faSchoolFlag,
    faScreenUsers,
    faScribble,
    faShareAlt,
    faShapes,
    faSitemap,
    faStarShooting,
    faStopwatch,
    faSyncAlt,
    faTable,
    faTags,
    faTimes,
    faTrashAlt,
    faTrophyAlt,
    faUndo,
    faUniversalAccess,
    faUser,
    faUserGroup,
    faUsers,
    faUsersClass,
    faUserTie,
    faCommentAltLines,
    faSignalBarsStrong,
} from '@fortawesome/pro-duotone-svg-icons';

import { 
    faAddressBook as fasAddressBook,
    faAngleUp as fasAngleUp,
    faArrowAltLeft as fasArrowAltLeft,
    faArrowAltRight as fasArrowAltRight,
    faArrowDown19 as fasArrowDown19,
    faArrowLeft as fasArrowLeft,
    faArrowRight as fasArrowRight,
    faArrowsRotate as fasArrowsRotate,
    faArrowsSpin as fasArrowsSpin,
    faArrowUpShortWide as fasArrowUpShortWide,
    faAward as fasAward,
    faBackwardStep as fasBackwardStep,
    faBadgeCheck as fasBadgeCheck,
    faBanBug as fasBanBug,
    faBarsFilter as fasBarsFilter,
    faBell as fasBell,
    faBellOn as fasBellOn,
    faBinoculars as fasBinoculars,
    faBolt as fasBolt,
    faBooks as fasBooks,
    faBoxArchive as fasBoxArchive,
    faBracketRound as fasBracketRound,
    faBracketRoundRight as fasBracketRoundRight,
    faBug as fasBug,
    faBullseyeArrow as fasBullseyeArrow,
    faCalculatorSimple as fasCalculatorSimple,
    faCaretUp as fasCaretUp,
    faCaretDown as fasCaretDown,
    faChartSimple as fasChartSimple,
    faChalkboardTeacher as fasChalkboardTeacher,
    faChevronLeft as fasChevronLeft,
    faChevronRight as fasChevronRight,
    faChevronsLeft as fasChevronsLeft,
    faChevronsRight as fasChevronsRight,
    faCircle as fasCircle,
    faCircleCheck as fasCircleCheck,
    faCircleExclamation as fasCircleExclamation, 
    faCircleSmall as fasCircleSmall,
    faCircleUser as fasCircleUser,
    faCompressAlt as fasCompressAlt,
    faCopy as fasCopy,
    faCube as fasCube,
    faCubes as fasCubes,
    faDeleteLeft as fasDeleteLeft,
    faDesktop as fasDesktop,
    faDivide as fasDivide,
    faEarthAmericas as fasEarthAmericas,
    faEllipsis as fasEllipsis,
    faEllipsisVertical as fasEllipsisVertical,
    faEnvelope as fasEnvelope,
    faEquals as fasEquals,
    faExternalLink as fasExternalLink,
    faExternalLinkAlt as fasExternalLinkAlt,
    faFileDownload as fasFileDownload,
    faFileImport as fasFileImport,
    faFileXmark as fasFileXmark,
    faFilePdf as fasFilePdf,
    faFilePen as fasFilePen,
    faFlagPennant as fasFlagPennant,
    faFloppyDisk as fasFloppyDisk,
    faFolder as fasFolder,
    faFolderPlus as fasFolderPlus,
    faFolders as fasFolders,
    faFont as fasFont,
    faGauge as fasGauge,
    faGear as fasGear,
    faGridHorizontal as fasGridHorizontal,
    faGripDots as fasGripDots,
    faGripDotsVertical as fasGripDotsVertical,
    faHandHoldingSeedling as fasHandHoldingSeedling,
    faHeadSideBrain as fasHeadSideBrain,
    faIdCard as fasIdCard,
    faImage as fasImage,
    faInboxFull as fasInboxFull,
    faLink as fasLink,
    faLinkSimple as fasLinkSimple,
    faList as fasList,
    faListTree as fasListTree,
    faLockAlt as fasLockAlt,
    faMagnifyingGlass as fasMagnifyingGlass,
    faMagnifyingGlassChart as fasMagnifyingGlassChart,
    faMinus as fasMinus,
    faPalette as fasPalette,
    faPaperclip as fasPaperclip,
    faPaperclipVertical as fasPaperclipVertical,
    faPaperPlaneTop as fasPaperPlaneTop,
    faPencil as fasPencil,
    faPenLine as fasPenLine,
    faPlus as fasPlus,
    faPoll as fasPoll,
    faPrint as fasPrint,
    faQrcode as fasQrcode,
    faQuestionCircle as fasQuestionCircle,
    faQuoteLeft as fasQuoteLeft,
    faQuoteRight as fasQuoteRight,
    faStethoscope as fasStethoscope,
    faRandom as fasRandom,
    faRightFromBracket as fasRightFromBracket,
    faSchool as fasSchool,
    faScreenUsers as fasScreenUsers,
    faScrewdriverWrench as fasScrewdriverWrench,
    faShapes as fasShapes,
    faShare as fasShare,
    faShieldCheck as fasShieldCheck,
    faSort as fasSort,
    faSortUp as fasSortUp,
    faSortDown as fasSortDown,
    faStar as fasStar,
    faSquare as fasSquare,
    faSquareQuestion as fasSquareQuestion,
    faTableList as fasTableList,
    faTag as fasTag,
    faTasks as fasTasks,
    faText as fasText,
    faThumbsUp as fasThumbsUp,
    faThumbsDown as fasThumbsDown,
    faTimes as fasTimes,
    faTrashAlt as fasTrashAlt,
    faTrashCan as fasTrashCan,
    faTrashRestoreAlt as fasTrashRestoreAlt,
    faTrashXmark as fasTrashXmark,
    faTriangleExclamation as fasTriangleExclamation,
    faUser as fasUser,
    faUserGroup as fasUserGroup,
    faUsers as fasUsers,
    faVolume as fasVolume,
    faPlay as fasPlay,
    faPause as fasPause,
    faWindowClose as fasWindowClose,
    faInfoCircle as fasInfoCircle,
    faFilm as fasFilm,
    faFilePlus as fasFilePlus,
    faCheck as fasCheck,
    faCircleInfo as fasCircleInfo,
    faUserTie as fasUserTie,
    faStickyNote as fasStickyNote,
    faRadiation as fasRadiation,
    faNoteSticky as fasNoteSticky,
    faX as fasX,
    faCommentDots as fasCommentDots,
    faUndo as fasUndo,
    faSwap as fasSwap,
    faTrashUndo as fasTrashUndo,
    faFileExcel as fasFileExcel,
    faCircleNotch as fasCircleNotch,
    faPenToSquare as fasPenToSquare,
    faLanguage as fasLanguage,
    faEye as fasEye,
    faSignalBarsStrong as fasSignalBarsStrong,
    faCloudArrowUp as fasCloudArrowUp,
} from '@fortawesome/pro-solid-svg-icons';

import {
    faCheckCircle as farCheckCircle,
    faCirclePlus as farCirclePlus,
    faClock as farClock,
    faExclamationCircle as farExclamationCircle,
    faStar as farStar,
} from '@fortawesome/pro-regular-svg-icons';

import {
    faCabinetFiling as falCabinetFiling,
    faChartBar as falChartBar,
    faQuestionCircle as falQuestionCircle,
    faChevronDown as falChevronDown,
    faPlusSquare as falPlusSquare,
    faImage as falImage,
    faTrashAlt as falTrashAlt,
    faSquareChevronDown as falSquareChevronDown,
    faSquareChevronUp as falSquareChevronUp,
    faChevronUp as falChevronUp,
    faFolderPlus as falFolderPlus,
    faCircleNotch as falCircleNotch,
    faPipe as falPipe,
} from '@fortawesome/pro-light-svg-icons';

import { 
    faFacebook,
    faLinkedin,
    faXTwitter,
    faYoutube 
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faAddressBook,
    faArchive,
    faArrowRight,
    faArrowsRotate,
    faAward,
    faBanBug,
    faBars,
    faBarsProgress,
    faBell,
    faBellOn,
    faBinoculars,
    faBookReader,
    faBooks,
    faBrain,
    faBug,
    faBuildingFlag,
    faBullseyeArrow,
    faCalculatorSimple,
    faCalendarAlt,
    faChalkboard,
    faChalkboardTeacher,
    faChalkboardUser,
    faChartBar,
    faChartSimple,
    faCheck,
    faChevronLeft,
    faChevronRight,
    faChevronDoubleDown,
    faChevronDoubleUp,
    faCircleUser,
    faClock,
    faClipboardList,
    faCommentAltLines,
    faCommentAltSmile,
    faCopy,
    faCubes,
    faDiagramProject,
    faDice,
    faEarthAmericas,
    faEnvelope,
    faEraser,
    faExternalLink,
    faEye,
    faFileContract,
    faFileDownload,
    faFileExport,
    faFileCsv,
    faFilePdf,
    faFilm,
    faFilters,
    faFolder,
    faFolders,
    faGear,
    faGrid2,
    faHandHoldingSeedling,
    faHeadSideBrain,
    faImage,
    faInfoCircle,
    faKeyboard,
    faLanguage,
    faLaptopHouse,
    faLink,
    faList,
    faListUl,
    faMagnifyingGlassChart,
    faNewspaper,
    faPencilAlt,
    faPoll,
    faPrint,
    faQrcode,
    faQuestionCircle,
    faRainbow,
    faRedo,
    faRightFromBracket,
    faRotateLeft,
    faRotateRight,
    faSave,
    faShareAlt,
    faSchoolFlag,
    faScreenUsers,
    faScribble,
    faShapes,
    faSitemap,
    faStarShooting,
    faStopwatch,
    faSyncAlt,
    faTable,
    faTags,
    faTimes,
    faTrashAlt,
    faTrophyAlt,
    faUndo,
    faUniversalAccess,
    faUser,
    faUserGroup,
    faUsers,
    faUsersClass,
    faUserTie,
    faSignalBarsStrong,
);

library.add(
    fasAddressBook,
    fasAngleUp,
    fasArrowAltLeft,
    fasArrowAltRight,
    fasArrowLeft,
    fasArrowRight,
    fasArrowsRotate,
    fasArrowsSpin,
    fasArrowUpShortWide,
    fasAward,
    fasBackwardStep,
    fasBadgeCheck,
    fasBanBug,
    fasBarsFilter,
    fasBell,
    fasBellOn,
    fasBinoculars,
    fasBolt,
    fasBooks,
    fasBoxArchive,
    fasBracketRound,
    fasBracketRoundRight,
    fasBug,
    fasBullseyeArrow,
    fasCalculatorSimple,
    fasCaretUp,
    fasCaretDown,
    fasChartSimple,
    fasCompressAlt,
    fasCheck,
    fasChevronLeft,
    fasChevronRight,
    fasChevronsLeft,
    fasChevronsRight,
    fasCircle,
    fasCircleCheck, 
    fasCircleExclamation, 
    fasArrowDown19,
    fasCircleInfo,
    fasCircleSmall,
    fasCircleUser,
    fasChalkboardTeacher,
    fasCopy,
    fasCube,
    fasCubes,
    fasDeleteLeft,
    fasDesktop,
    fasDivide,
    fasEarthAmericas,
    fasEllipsis,
    fasEllipsisVertical,
    fasEnvelope,
    fasEquals,
    fasExternalLink,
    fasExternalLinkAlt,
    fasFileDownload,
    fasFileImport,
    fasFileXmark,
    fasFilePen,
    fasFilePlus,
    fasFilm,
    fasFilePdf,
    fasFlagPennant,
    fasFloppyDisk,
    fasFolder,
    fasFolderPlus,
    fasFolders,
    fasFont,
    fasGauge,
    fasGear,
    fasGridHorizontal,
    fasGripDots,
    fasGripDotsVertical,
    fasHandHoldingSeedling,
    fasHeadSideBrain,
    fasIdCard,
    fasImage,
    fasInboxFull,
    fasInfoCircle,
    fasLink,
    fasLinkSimple,
    fasList,
    fasListTree,
    fasLockAlt,
    fasMagnifyingGlass,
    fasMagnifyingGlassChart,
    fasMinus,
    fasPalette,
    fasPaperclip,
    fasPaperclipVertical,
    fasPaperPlaneTop,
    fasPause,
    fasPencil,
    fasPenLine,
    fasPlay,
    fasPlus,
    fasPoll,
    fasPrint,
    fasQrcode,
    fasQuestionCircle,
    fasQuoteLeft,
    fasQuoteRight,
    fasStethoscope,
    fasRandom,
    fasRightFromBracket,
    fasSchool,
    fasScreenUsers,
    fasScrewdriverWrench,
    fasShapes,
    fasShare,
    fasShieldCheck,
    fasSort,
    fasSortUp,
    fasSortDown,
    fasSquare,
    fasSquareQuestion,
    fasStar,
    fasTableList,
    fasTag,
    fasTasks,
    fasText,
    fasThumbsUp,
    fasThumbsDown,
    fasTimes,
    fasTrashAlt,
    fasTrashCan,
    fasTrashRestoreAlt,
    fasTrashXmark,
    fasTriangleExclamation,
    fasUndo,
    fasUser,
    fasUserGroup,
    fasUsers,
    fasUserTie,
    fasVolume,
    fasWindowClose,
    fasInfoCircle,
    fasFilm,
    fasFilePlus,
    fasSquare,
    fasSquareQuestion,
    fasCheck,
    fasCircleInfo,
    fasUserTie,
    fasStickyNote,
    fasRadiation,
    fasNoteSticky,
    fasX,
    fasCommentDots,
    fasSwap,
    fasTrashUndo,
    fasFileExcel,
    fasCircleNotch,
    fasPenToSquare,
    fasLanguage,
    fasEye,
    fasSignalBarsStrong,
    fasCloudArrowUp,
);

library.add(
    farCheckCircle,
    farCirclePlus,
    farClock,
    farExclamationCircle,
    farStar,
);

library.add(
    falCabinetFiling,
    falChartBar,
    falQuestionCircle,
    falChevronDown,
    falPlusSquare,
    falImage,
    falTrashAlt,
    falSquareChevronDown,
    falSquareChevronUp,
    falChevronUp,
    falFolderPlus,
    falCircleNotch,
    falPipe,
);

library.add(
    faFacebook,
    faLinkedin,
    faXTwitter,
    faYoutube,
);
