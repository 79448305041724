window.Nextgenmath = window.Nextgenmath || {};

import './NextGenMathRoutes.js';

Nextgenmath.chartBgColors = ['rgba(255, 120, 52, .4)', 'rgba(57, 135, 216, .4)', 'rgba(80, 81, 81, .4)', 'rgba(187, 223, 0, .4)'];
Nextgenmath.chartBorderColors =  ['rgba(255, 120, 52, 1)', 'rgba(57, 135, 216, 1)', 'rgba(80, 81, 81, 1)', 'rgba(187, 223, 0, 1)'];
Nextgenmath.isLocal = (process.env.NODE_ENV !== 'production');

const metaData = {
    csrfToken: document.head.querySelector('meta[name="csrf-token"]'),
    // user: document.head.querySelector('meta[name="user"]'),
    // viewAsUser: document.head.querySelector('meta[name="view-as"]'),
    // notifications: document.head.querySelector('meta[name="notifications"]'),
    // clever: document.head.querySelector('meta[name="clever"]'),
    // premiumFeatures: document.head.querySelector('meta[name="premium-features"]'),
    // academicDates: document.head.querySelector('meta[name="academic-dates"]'),
};

for (const prop in metaData) {
    if (metaData[prop]) {
        if (prop == 'csrfToken') {
            if (window.axios)
                window.axios.defaults.headers.common['X-CSRF-TOKEN'] = metaData[prop].content; 

            continue;
        }

        if (prop == 'clever') {
            Nextgenmath[prop] = {id: metaData[prop].content || null};

            continue;
        }

        Nextgenmath[prop] = JSON.parse(atob(metaData[prop].content));
        metaData[prop].remove();
    }
}

/* if (Nextgenmath.user)
    Object.assign(Nextgenmath.user, {
        isAdmin: Nextgenmath.user.role == 'schoolAdmin' || Nextgenmath.user.role == 'districtAdmin',
        isDistrictAdmin: Nextgenmath.user.role == 'districtAdmin',
        isSchoolAdmin: Nextgenmath.user.role == 'schoolAdmin',
        isTeacher: Nextgenmath.user.role == 'teacher',
        isStudent: Nextgenmath.user.role == 'student',
        isNative: Nextgenmath.user.access_type == 'Native',
        premium_features: Nextgenmath.premiumFeatures,
        academic_dates: Nextgenmath.academicDates,
    });

if (Nextgenmath.viewAsUser)
    Object.assign(Nextgenmath.viewAsUser, {
        isAdmin: Nextgenmath.viewAsUser.role == 'schoolAdmin' || Nextgenmath.viewAsUser.role == 'districtAdmin',
        isDistrictAdmin: Nextgenmath.viewAsUser.role == 'districtAdmin',
        isSchoolAdmin: Nextgenmath.viewAsUser.role == 'schoolAdmin',
        isTeacher: Nextgenmath.viewAsUser.role == 'teacher',
        isStudent: Nextgenmath.viewAsUser.role == 'student',
        isNative: Nextgenmath.viewAsUser.access_type == 'Native',
        premium_features: Nextgenmath.premiumFeatures,
        academic_dates: Nextgenmath.academicDates,
    }); */